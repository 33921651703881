$bg-col: #fff
$border-col: #ccc
$bg-unfocused-col: #eee

%button_style
	margin: 0
	outline: 0
	box-shadow: none
	padding: 8px
	border-radius: 4px
	background-color: $bg-col
	border: 1px solid $border-col

	color: #333
	cursor: pointer
	font-family: inherit
	font-weight: normal

	transition: background-color 0.2s, border-color 0.2s

	&:hover, &:focus
		background-color: #eee
		border-color: #aaa

.Calendar
	display: grid
	grid-template-rows: auto 1fr
	height: clamp(550px, 80vw, 700px)
	width: clamp(100%, 800px, 100%)

	overflow: hidden
	user-select: none
	border-radius: 8px
	border: 1px solid $border-col
	background-color: $border-col

	h1, h2, h3, h4, h5, h6
		font-weight: normal
		font-family: inherit
		font-family: "Montserrat", "Roboto", "Open Sans", "Segoe UI", "Calibiri", sans-serif

	.Calendar-Header
		height: 3rem
		position: relative
		background-color: $bg-col
		border-bottom: 1px solid $border-col

		h3
			margin: 0
			padding: 0.75rem 5rem
			line-height: 1
			text-align: center
			font-size: 1.5rem

		button
			@extend %button_style

			position: absolute
			top: 6px
			left: 6px
			height: 34px

			&.Next
				left: initial
				right: 6px

	.Calendar-DayBar
		display: grid
		text-align: center
		background-color: $bg-col
		border-bottom: 1px solid $border-col
		grid-template-columns: repeat(7, 1fr)

		span
			padding: 8px
			opacity: 0.7
			font-family: inherit

	.Calendar-Body
		position: relative
		display: grid
		grid-template-rows: auto 1fr
		overflow-x: auto
		overflow-y: hidden

		.Calendar-PageWrap
			position: relative
			margin-bottom: -1px
			min-width: 700px

			.Calendar-Page
				position: absolute
				width: 100%
				height: 100%
				
				&.Animate-exit, &.Animate-appear-active.Animate-appear-active, &.Animate-enter-active.Animate-enter-active
					opacity: 1
					transform: scale(1)
					transition: opacity 0.3s, transform 0.3s

				&.Animate-exit-active, &.Animate-appear, &.Animate-enter
					will-change: transform
					opacity: 0.01
					transform: scale(0.95)

	.Calendar-Page
		display: flex
		flex-direction: column

		.Calendar-Row
			flex-grow: 1
			flex-basis: 0%
			display: grid
			grid-gap: 1px
			padding-bottom: 1px
			grid-template-columns: repeat(7, 1fr)

			.Calendar-Day
				position: relative
				background-color: $bg-unfocused-col
				display: grid
				grid-gap: 4px
				grid-auto-rows: 24px
				padding-top: 28px
				padding-bottom: 8px

				&.CurrentMonth
					background-color: $bg-col

				.Calendar-DayIndicator
					position: absolute
					top: 4px
					right: 4px
					width: 20px
					height: 20px

					opacity: 0.7
					line-height: 1.8
					font-size: 12px
					border-radius: 50%
					text-align: center
					font-family: inherit
					background-color: $bg-unfocused-col

				&.CurrentDay .Calendar-DayIndicator
					background-color: #34434f
					color: #fff

				.Calendar-Event
					--color: #34434f

					padding: 0
					outline: 0
					border: none
					display: flex
					overflow: hidden
					margin-right: -1px
					align-items: center

					cursor: pointer
					background-color: $bg-col
					border-top: 1px solid var(--color)
					border-bottom: 1px solid var(--color)

					transition: background-color 0.15s, color 0.15s

					&.start
						margin-left: 4px
						border-top-left-radius: 4px
						border-bottom-left-radius: 4px
						border-left: 1px solid var(--color)

					&.end
						margin-right: 4px
						border-top-right-radius: 4px
						border-bottom-right-radius: 4px
						border-right: 1px solid var(--color)

					.Calendar-EventColorIndicator
						display: inline-block
						width: 10px
						height: 10px
						border-radius: 50%
						background-color: var(--color)
						margin: 6px

						transition: background-color 0.15s

					span
						max-width: calc(100% - 32px)

						font-size: 13px
						overflow: hidden
						white-space: nowrap
						text-overflow: ellipsis

					&.Selected
						color: white
						font-weight: 600
						background-color: var(--color)

						.Calendar-EventColorIndicator
							background-color: white

					&.Hidden
						opacity: 0.4

						&:hover
							opacity: 1
