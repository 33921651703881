.ImageView
	position: relative
	overflow: hidden
	object-position: 50% 50%

	width: 100%
	height: auto
	margin: 0 auto

	picture
		object-position: inherit

	img
		display: block
		object-fit: cover
		width: 100%
		height: 100%
		object-position: inherit

.ImageView-Modal
	position: fixed
	z-index: 255
	top: 0
	left: 0
	bottom: 0
	right: 0

	padding: 24px
	background-color: transparentize(#0b171fc7, 0.1)
	backdrop-filter: blur(12px)

	img
		width: 100%
		height: 100%
		object-fit: contain
